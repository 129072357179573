import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { ContactUs } from './ContactFormC';
import { handlePlayMusic } from './Music';

function HomePageC() {
  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)' }}></div>
      <Carousel style={{ height: '100%' }}>
        <Carousel.Item interval={600000}>
        <div style={{ position: 'absolute', top: 10, right: '15%' }}>
    <button onClick={handlePlayMusic} className="btn" style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>► </button>
  </div>
          <img
            className="d-block w-100"
            src="blue6.jpg"
            alt="Image One"
            style={{ objectFit: 'cover', height: '100vh' }}
          />
          <Carousel.Caption>
            <h1 style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>歡迎光臨ruilin<span style={{ fontWeight: 'bold', color: '#1793d1', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>.moe</span></h1>
            <div>
              <Link to="/" className="btn mr-2">English</Link>
              <Link to="/Japanese" className="btn ms-5">日本語</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={600000}>
            
          <img
            className="d-block w-100"
            src="blue4.jpg"
            alt="Image Two"
            style={{ objectFit: 'cover', height: '100vh' }}
          />
          <Carousel.Caption>
            <h1 style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>我擁有的網頁</h1>
             <table className="table table-striped table-transparent" style={{ color: '#fff', backgroundColor: 'transparent' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>域名</th>
                  <th>描述</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>www.ruilin.moe</td>
                  <td>本頁面</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td><a href="https://blog.ruilin.moe">blog.ruilin.moe</a></td>
                  <td>最新死宅博客</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td><a href="https://www.ruilinp.com/">www.ruilinp.com</a></td>
                  <td>老主頁 2023-2024</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td><a href="https://blog.ruilinp.com/">blog.ruilinp.com</a></td>
                  <td>老博客 2023-2024</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td><a href="https://invidious.ruilinp.com/">invidious.ruilinp.com</a></td>
                  <td>我的 invidious instance</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td><a href="https://aronachat.ruilin.moe/">aronachat.ruilin.moe</a></td>
                  <td>与阿罗娜酱交谈(openai api)</td>
                </tr>
              </tbody>
            </table>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={600000}>
          <img
            className="d-block w-100"
            src="blue5.jpg"
            alt="Image Three"
            style={{ objectFit: 'cover', height: '100vh' }}
          />
          <Carousel.Caption>
            <h1 style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>關於我</h1>
            <p style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Graduate ECE at Western University, London. Arch Linux x86 Thinkpad T460. 沒啥好說的。</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={600000}>
          <img
            className="d-block w-100"
            src="2PvxbRjv.jpeg"
            alt="Image Three"
            style={{ objectFit: 'cover', height: '100vh' }}
          />
          <Carousel.Caption>
            <h1 style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>科技樹</h1>
            <div style={{ color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li>
                  <p><strong>編程語言:</strong></p>
                  <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>

                  <li><span style={{ fontWeight: 'bold', color: '#03589b', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Dart</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#f7df1e', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>JavaScript</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#347ab4', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Py</span><span style={{ fontWeight: 'bold', color: '#ffca1d', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>thon</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#db380f', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Ja</span><span style={{ fontWeight: 'bold', color: 'rgba(155,183,239,255)', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>va</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#054e8d', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>C</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#054e8d', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>C#</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#266ac0', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>R</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#017cc2', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>SAS</span></li>
                  </ul>
                </li>
                <li>
                  <p><strong>框架:</strong></p>
                  <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                  <li><span style={{ fontWeight: 'bold', color: '#7c14f6', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Bootstrap</span></li>

                  <li><span style={{ fontWeight: 'bold', color: '#47c4fa', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Flut</span><span style={{ fontWeight: 'bold', color: '#00559e', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>ter</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#64d8ff', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>React.js</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#3e863d', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Node.js</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#092d1f', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Django</span></li>
                  </ul>
                </li>
                <li>
                  <p><strong>其他:</strong></p>
                  <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                  <li><span style={{ fontWeight: 'bold', color: 'black', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Unity</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#e85326', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>HTML</span></li>
                    <li><span style={{ fontWeight: 'bold', color: '#2853e7', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>CSS</span></li>
                    <li><span style={{ fontWeight: 'bold', color: 'black', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Latex</span></li>
                  </ul>
                </li>
              </ul>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={600000}>
          <img
            className="d-block w-100"
            src="blue7.png"
            alt="Image Three"
            style={{ objectFit: 'cover', height: '100vh' }}
          />
          <Carousel.Caption>
            <h1 style={{ fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>留言</h1>
            <ContactUs/>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <img
        src="arona-blue-archive.gif" 
        alt="GIF"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '15%', 
          height: 'auto',
        }}
      />
    </div>
  );
}

export default HomePageC;
