import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Change BrowserRouter to Router
import HomePage from './HomePage';
import HomePageC from './HomePageC';
import HomePageJ from './HomePageJ';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} /> 
        <Route exact path="/Chinese" element={<HomePageC />} /> 
        <Route exact path="/Japanese" element={<HomePageJ />} /> 
      </Routes>
    </Router>
  );
}

export default App;
