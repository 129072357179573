import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.css';

export const ContactUs = () => {
  const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_hkjcvig', 'template_ourtssc', form.current, {
        publicKey: 's4UxeHCiRa0rI_LbN',
      })
      .then(
        () => {
          setMessage('Email sent successfully!');
        },
        (error) => {
          setMessage(`Failed to send email: ${error.text}`);
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="form-group">
        <label htmlFor="user_name" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>名前</label>
        <input type="text" className="form-control" id="user_name" name="user_name" required/>
      </div>
      <div className="form-group">
        <label htmlFor="user_email" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>Eメール</label>
        <input type="email" className="form-control" id="user_email" name="user_email" required/>
      </div>
      <div className="form-group">
        <label htmlFor="message" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>メッセージ</label>
        <textarea className="form-control" id="message" name="message" rows="3" required></textarea>
      </div>
      <button type="submit" className="mt-3 btn btn-light" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Send</button>
      {message && <div className={`mt-3 alert ${message.includes('successfully') ? 'alert-success' : 'alert-danger'}`}>{message}</div>}
    </form>
  );
};
